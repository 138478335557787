import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const DefaultLogo = () => (
  <>
    <StaticImage
      src={"../../../images/product/default-logo.png"}
      alt={"place your logo here"}
      width={300}
      height={300}
      placeholder={"tracedSVG"}
    />
  </>
);

export default DefaultLogo;
