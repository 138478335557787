import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const MarkdownParser = (props: { body: string }) => (
  <div className="prose min-w-full list-disc prose-p:mt-0 prose-p:mb-0 prose-ul:text-black prose-li:leading-tight">
    <ReactMarkdown>{props.body}</ReactMarkdown>
  </div>
);

export default MarkdownParser;
