import React from "react";

import CustomLink from "../common/custom_link";
import MarkdownParser from "../common/markdown_parser";
import BookItem from "./book_item";
import ThirdPartyRow from "./third_party_row";

const thirdParty: { label: string; key: string }[] = [
  {
    label: "代理店",
    key: "agency",
  },
  {
    label: "価格",
    key: "agencyPrice",
  },
  {
    label: "トライアル",
    key: "agencyTrial",
  },
  {
    label: "FAQ",
    key: "agencyFaq",
  },
  {
    label: "ガイド／マニュアル",
    key: "guideManual",
  },
  {
    label: "書籍",
    key: "book",
  },
  {
    label: "動画",
    key: "video",
  },
  {
    label: "サポート",
    key: "agencySupport",
  },
  {
    label: "コミュニティー",
    key: "agencyCommunity",
  },
  {
    label: "イベント",
    key: "agencyEvent",
  },
  {
    label: "ブログ",
    key: "agencyBlog",
  },
];

const ThirdPartyProductInfo = (props: { product: any }) => {
  const introductoryCompany =
    props.product?.introductoryCompany?.data?.introductoryCompany ?? "";
  return (
    <div className="w-full">
      {thirdParty.map((row) => (
        <ThirdPartyRow key={row.label} label={row.label}>
          {props.product[row.key]?.map((item) => (
            <div key={item.name}>
              <CustomLink
                key={item.name}
                to={item.url}
                className="underline text-dx-theme-link"
              >
                {item.name}
              </CustomLink>
              {/* for book */}
              {row.key === "book" && (
                <div className="flex flex-wrap gap-2 mt-2">
                  <BookItem label={"ダウンロード"} to={item.directUrl} />
                  <BookItem label={"Amazon Kindle"} to={item.amazon} />
                  <BookItem label={"Googleブックス"} to={item.google} />
                  <BookItem label={"楽天市場"} to={item.rakuten} />
                </div>
              )}
            </div>
          ))}
        </ThirdPartyRow>
      ))}
      <ThirdPartyRow key={"introductoryCompany"} label="導入企業">
        <MarkdownParser body={introductoryCompany} />
      </ThirdPartyRow>
    </div>
  );
};

export default ThirdPartyProductInfo;
