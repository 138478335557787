import React from "react";
import Header from "../components/common/header";
import Footer from "../components/common/footer";
import Sidebar from "../components/common/sidebar";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => (
  <>
    <Header />
    <div className="container flex my-5 overflow-hidden gap-x-5">
      <div className="hidden w-[280px] flex-none lg:inline">
        <Sidebar />
      </div>
      <div className="z-0 max-w-full grow">{children}</div>
    </div>
    <Footer />
  </>
);

export default Layout;
