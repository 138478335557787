import { Link } from "gatsby";
import React from "react";

export type IBreadcrumbItem = {
  to?: string;
  label: string;
  isHome?: boolean;
};

export const BreadcrumbItem = ({ to, label, isHome }: IBreadcrumbItem) => {
  return (
    <div className="font-bold">
      {to === undefined ? (
        <>{label}</>
      ) : (
        <Link
          to={to}
          className="flex items-center justify-center gap-3 font-medium text-black"
        >
          {isHome && (
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.80748 0L0 6.17128V16.7742H6.93542V11.1086H10.6775V16.7742H17.6129V6.17128L8.80748 0Z"
                fill="black"
                fillOpacity="0.54"
              />
            </svg>
          )}
          <div className="font-bold text-dx-theme-link">{label}</div>
          <span>/</span>
        </Link>
      )}
    </div>
  );
};
