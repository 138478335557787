import React from "react";
import CustomLink from "../common/custom_link";

const ProductWebsite = (props: { product: any }) => {
  const { product } = props;
  const url = product.url ?? "";
  return (
    <div className="space-y-2">
      <div className="flex gap-4">
        <span className="min-w-[80px] max-w-[80px] sm:min-w-[130px]">
          製品ウェブサイト
        </span>
        <span>:</span>
        <CustomLink className="break-all text-dx-theme-product-link" to={url}>
          {url}
        </CustomLink>
      </div>
      <div className="flex gap-4">
        <span className="min-w-[80px] max-w-[80px] sm:min-w-[130px]">
          販売元企業
        </span>
        <span>:</span>

        <CustomLink
          className="text-dx-theme-product-link"
          to={product?.brand?.url}
        >
          {product?.brand?.name}
        </CustomLink>
      </div>
    </div>
  );
};

export default ProductWebsite;
