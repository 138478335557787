import React from "react";
import CustomButton from "../common/button";
import CustomLink from "../common/custom_link";

type BookItemProps = {
  label: string;
  to?: string;
};

const BookItem = ({ label, to }: BookItemProps) => {
  const isInActive = !to || to.length <= 0;
  return (
    <CustomLink to={to} key={label}>
      <CustomButton isInActive={isInActive}>{label}</CustomButton>
    </CustomLink>
  );
};
export default BookItem;
