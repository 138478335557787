import React from "react";

type ThirdPartyRowProps = {
  label: string;
  children: React.ReactNode;
};

const ThirdPartyRow = ({ label, children }: ThirdPartyRowProps) => (
  <div className="flex items-start border-b border-dx-theme-divier">
    <span className="min-w-[70px] max-w-[70px] sm:max-w-[200px]  py-2 font-bold  opacity-[87%] sm:min-w-[148px]">
      {label}
    </span>
    <span className="flex flex-col gap-2 py-2 pl-4">{children}</span>
  </div>
);
export default ThirdPartyRow;
