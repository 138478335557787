import React from "react";
import { BreadcrumbItem, IBreadcrumbItem } from "./breadcrumb_item";

type BreadCrumbProp = {
  items?: IBreadcrumbItem[];
};

const BreadCrumb = ({ items }: BreadCrumbProp) => (
  <nav className="flex items-center pb-4 text-base" aria-label="Breadcrumb">
    <ol className="flex flex-wrap items-center gap-x-1 gap-y-1 md:gap-x-3">
      <li className="inline-flex items-end">
        <BreadcrumbItem to="/#categories" label=" カテゴリー" isHome />
      </li>
      {items &&
        items.map((item) => (
          <li key={item.label}>
            <BreadcrumbItem {...item} />
          </li>
        ))}
    </ol>
  </nav>
);

export default BreadCrumb;
