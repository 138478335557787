import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { Fragment } from "react";

type FullScreenImageProps = {
  isOpen: boolean;
  closeModal: () => void;
  children: React.ReactNode;
};

const FullScreenImage = ({
  children,
  isOpen,
  closeModal,
}: FullScreenImageProps) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 cursor-zoom-out bg-black bg-opacity-80" />
          </Transition.Child>

          <div className="fixed inset-0 cursor-zoom-out overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  onClick={closeModal}
                  className="max-h-9/12 z-[100] bg-white p-4 w-screen max-w-7xl transform overflow-hidden shadow-xl transition-all"
                >
                  <div className="">{children}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default FullScreenImage;
