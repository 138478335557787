import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import useCurrentWidth from "../../hooks/use_width";
import FullScreenImage from "./full_screen_image";

const MOBILE_BREAK_POINT = 640;

const ProductFeatureImages = (props: { product: any }) => {
  const { product } = props;
  const [isOpen, setIsOpen] = useState(
    Array(product?.featuredImage?.length ?? 0).fill(false)
  );

  const handleClose = () => {
    setIsOpen((prev) => {
      return prev.map((e) => false);
    });
  };

  const handleOpen = (index: number) => {
    // console.log("handleOpen is clicked ${index}");
    setIsOpen((prev) => {
      const newList = prev.map(() => false);
      newList[index] = true;
      return newList;
    });
  };
  return (
    // <ProductFeatureImagesContainer className="mt-10 aspect-w-16 aspect-h-9 ">
    <ProductFeatureImagesContainer>
      {product?.featuredImage?.map((e, index) => {
        const image = getImage(e.localFile);
        if (image) {
          return (
            <ProductFeatureImagesItem
              key={"image " + index}
              isOpen={isOpen}
              handleOpen={handleOpen}
              handleClose={handleClose}
              index={index}
            >
              <div className="grid h-full">
                <GatsbyImage
                  className="my-auto"
                  image={image}
                  alt={e?.alternativeText ?? ""}
                />
              </div>
            </ProductFeatureImagesItem>
          );
        }
        return <></>;
      })}
    </ProductFeatureImagesContainer>
  );
};

const ProductFeatureImagesContainer = (props): JSX.Element => {
  const width = useCurrentWidth();
  const { className, ...rest } = props;
  if (width < MOBILE_BREAK_POINT) {
    return (
      <div className="mt-10 border shadow-lg border-opacity-30">
        <Carousel
          autoPlay
          infiniteLoop
          interval={5000}
          showStatus={false}
          showThumbs={false}
          dynamicHeight={false}
          stopOnHover={true}
          {...props}
        />
      </div>
    );
  }
  // Tablet and Desktop View
  return (
    <div
      className={`grid grid-cols-2 gap-2 lg:grid-cols-3 lg:gap-4 ${className}`}
      {...rest}
    ></div>
  );
};

type ProductFeatureImagesItemProps = {
  isOpen: boolean[];
  children: any;
  className?: string;
  index: number;
  handleOpen: (index: number) => void;
  handleClose: () => void;
};

const ProductFeatureImagesItem = ({
  className,
  children,
  index,
  isOpen,
  handleOpen,
  handleClose,
}: ProductFeatureImagesItemProps) => {
  const width = useCurrentWidth();
  if (!width || width == 0 || width > MOBILE_BREAK_POINT) {
    return (
      <>
        <FullScreenImage isOpen={isOpen[index]} closeModal={handleClose}>
          {children}
        </FullScreenImage>
        <button
          className="border shadow-lg cursor-zoom-in border-dx-theme-divier border-opacity-30 "
          onClick={() => {
            handleOpen(index);
          }}
        >
          {children}
        </button>
      </>
    );
  }
  return children;
};

export default ProductFeatureImages;
