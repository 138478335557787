import React, { useState } from "react";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import CustomLink from "../common/custom_link";
import CustomButton from "../common/button";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

type IDXDailyPost = {
  link?: string;
  newstitle?: string;
  releasedate?: string;
};

type DxDailyPostsProps = {
  dxdailyId: string;
  postsList: IDXDailyPost[];
};

type DXDailyPostsState = {
  hasMore: boolean;
  start: number;
  loadedPost: IDXDailyPost[];
};

const POST_BY_REQUEST = 10;

const DxDailyPosts = ({ postsList, dxdailyId }: DxDailyPostsProps) => {
  const isPostListValid = postsList && postsList.length > 0;

  const defaultState: DXDailyPostsState = {
    hasMore: postsList && postsList.length >= POST_BY_REQUEST,
    start: postsList?.length ?? 0,
    loadedPost: [],
  };

  const [localState, setLocalState] = useState(defaultState);
  const { promiseInProgress } = usePromiseTracker({ area: "dxDailyPost" });

  const loadMorePost = async () => {
    try {
      const response = await trackPromise(
        fetch("https://dxdailypost.com/api/graphql", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
          query GetDailyPost($dxdailyId: String!, $start: Int! ) {
              scrapingData(
                where: { companyname: $dxdailyId }
                limit: 10
                start: $start
                sort: "releasedate:desc"
              ) {
                link
                newstitle
                releasedate
              }
          }
        `,
            variables: {
              dxdailyId: dxdailyId,
              start: localState.start,
            },
          }),
        }),
        "dxDailyPost"
      );
      const json = await response.json();
      const loadedPost = json.data.scrapingData;
      // console.log("Has More: " + (loadedPost.length == 10));
      setLocalState((prev) => {
        return {
          hasMore: loadedPost.length == 10,
          loadedPost: [...prev.loadedPost, ...loadedPost],
          start: prev.start + POST_BY_REQUEST,
        };
      });
      // console.log(loadedPost);
    } catch (e) {
      setLocalState((prev) => {
        return {
          hasMore: false,
          loadedPost: [...prev.loadedPost],
          start: prev.start,
        };
      });
    }
  };

  let allPost: IDXDailyPost[] = [];
  if (isPostListValid) {
    allPost = [...postsList, ...localState.loadedPost];
  }

  return (
    <>
      <h2 className="mb-4 font-bold font-sm">最新ニュース</h2>
      {/* {JSON.stringify(data.dxdaily, null, 2)} */}
      <div className="flex flex-col gap-4">
        {allPost?.map((post, index) => (
          <div key={post.newstitle ?? "post " + index}>
            <div>
              {format(new Date(post.releasedate ?? ""), "yyyy.M.d (E)", {
                locale: ja,
              })}
            </div>
            {/* <div>{format(Posts.releasedate, "'Today is a' eeee")}</div> */}
            <CustomLink to={post.link} className="underline text-dx-theme-link">
              {post.newstitle}
            </CustomLink>
          </div>
        ))}
      </div>
      {localState.hasMore && (
        <div className="flex justify-center md:justify-start">
          <CustomButton
            onClick={loadMorePost}
            isInActive={promiseInProgress}
            isLoading={promiseInProgress}
            className="mt-8 mb-4"
          >
            Load More
          </CustomButton>
        </div>
      )}
    </>
  );
};

export default DxDailyPosts;
