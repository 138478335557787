import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

import BreadCrumb from "../components/common/breadcrumb";
import { IBreadcrumbItem } from "../components/common/breadcrumb/breadcrumb_item";
import CustomLink from "../components/common/custom_link";
import DefaultLogo from "../components/common/default_product_logo";
import MarkdownParser from "../components/common/markdown_parser";
import DxDailyPosts from "../components/product/dxdaily_news";
import ProductFeatureImages from "../components/product/feature_images";
import ProductWebsite from "../components/product/product_website";
import ThirdPartyProductInfo from "../components/product/third_party";
import SEO from "../components/seo";
import { ICategory } from "../hooks/use_category";
import Layout from "../layout/";

const ProductPage = ({ data, location, pageContext }) => {
  const product = data?.strapiProduct;
  const categories = product?.categories ?? [];
  const localizedCategories = localizeCategories(categories, "ja");
  const passedCategory: any = location.state;
  const currentCategory = getCurrentCategory(
    localizedCategories,
    passedCategory
  );
  const { dxdailyId } = pageContext;

  const breadcumbItems: IBreadcrumbItem[] = [];

  // Push category data to breadcrumb
  if (currentCategory) {
    breadcumbItems.push({
      label: currentCategory?.name,
      to: `/${currentCategory.slug}/`,
    });
  }

  // Push product data to breadcrumb
  breadcumbItems.push({
    label: product.productName,
  });

  const logo = getImage(product?.logo?.localFile);

  const basicInfo: { name: string; url: string }[] = [
    {
      name: "製品ウェブサイト",
      url: product?.url,
    },
    {
      name: "価格",
      url: product?.price,
    },
    {
      name: "トライアル",
      url: product?.trial,
    },
    {
      name: "FAQ",
      url: product?.faq,
    },
    {
      name: "運用ガイド",
      url: product?.operationGuide,
    },
    {
      name: "インテグレーションガイド",
      url: product?.integrationGuide,
    },
    {
      name: "開発者向けガイド",
      url: product?.developersGuide,
    },
    {
      name: "リソースライブラリー",
      url: product?.resourceLibrary,
    },
    {
      name: "ナレッジベース",
      url: product?.knowledgeBase,
    },
    {
      name: "サポート",
      url: product?.support,
    },
    {
      name: "ブログ",
      url: product?.blog,
    },
    {
      name: "ニュース",
      url: product?.news,
    },
    {
      name: "Twitter",
      url: product?.twitter,
    },
    {
      name: "Facebook",
      url: product?.facebook,
    },
    {
      name: "LinkedIn",
      url: product?.linkedIn,
    },
    {
      name: "YouTube",
      url: product?.youtube,
    },
    {
      name: "Vimeo",
      url: product?.vimeo,
    },
    {
      name: "プレスリリース",
      url: product?.pressRelease,
    },
    {
      name: "コミュニティー",
      url: product?.community,
    },
    {
      name: "G2",
      url: product?.g2Com,
    },
  ];

  const keyFeatures = product.keyFeatures?.data?.keyFeatures ?? "";
  return (
    <Layout>
      <BreadCrumb items={breadcumbItems} />
      {/* <pre>{JSON.stringify(product, undefined, 2)}</pre> */}
      <div className="flex flex-col items-start justify-between w-full gap-8 mt-5 gap-y-2 sm:mt-8 sm:flex-row">
        <div>
          <h1 className="mb-4 leading-none dx-h3 sm:mb-4">{product?.productName}</h1>
          {/* Top Section */}
          <div className="hidden mt-5 sm:inline-block">
            <ProductWebsite product={product} />
          </div>
        </div>
        {/* Logo */}
        <div
          style={{ backgroundColor: product?.logoBackgroundColor }}
          className="inline-flex aspect-square w-full max-w-[400px] grow-0  items-center justify-center rounded-md border-2 border-dx-theme-border border-opacity-25 p-4 duration-200 sm:max-w-[172px] shadow-md"
        >
          {logo ? (
            <div className="w-full">
              <GatsbyImage image={logo} alt={product?.productName} />
            </div>
          ) : (
            <DefaultLogo />
          )}
        </div>
        <div className="block mt-5 sm:hidden">
          <ProductWebsite product={product} />
        </div>
      </div>

      {/* end of top section */}
      {/* Overview */}
      {product.overview && (
        <>
          <h2 className="mt-16 font-bold dx-h5">概要</h2>
          <p className="mt-2">{product.overview}</p>
        </>
      )}
      {/* end of overview */}
      {/* Key Features */}
      {keyFeatures && (
        <>
          <h2 className="mt-10 font-bold dx-h5">主な機能</h2>
          <MarkdownParser body={keyFeatures} />
        </>
      )}
      {/* end of keyfeature */}
      {/* Feature Images */}
      <div className="h-0 md:min-h-[2.5rem]"></div>
      <ProductFeatureImages product={product} />
      {/* End of Feature Images */}
      {/* Various Basic Info */}
      <h2 className="mt-10 font-bold dx-h5">各種基本情報</h2>
      <div className="grid grid-cols-4 gap-2 mt-4 text-sm text-center sm:grid-cols-6 xl:grid-cols-10">
        {basicInfo.map((info) => (
          <CustomLink
            to={info.url}
            className={`inline-flex aspect-square w-full cursor-pointer  items-center justify-center rounded-md border  p-1 duration-200 
              ${
                info?.url?.length ?? 0 > 0
                  ? "border-dx-theme-link text-dx-theme-link hover:border-opacity-0 hover:bg-dx-theme-item-highlight hover:shadow-md"
                  : "border-dx-theme-border text-dx-theme-border"
              } 
              `}
            key={info.name}
          >
            {info.name}
          </CustomLink>
        ))}
      </div>
      {/* End of Various Basic Info */}
      {/* {JSON.stringify(product?.featuredImage, null, 2)} */}

      {/* Third Party Content */}
      <h2 className="mt-10 font-bold dx-h5">
        日本語コンテンツ（サードパーティー）
      </h2>
      <div className="flex flex-col-reverse gap-4 p-4 mt-6 border-2 rounded-md shadow-lg border-dx-theme-product-border border-opacity-20 md:flex-row">
        <ThirdPartyProductInfo product={product} />
        <div className="w-full md:w-[250px] md:min-w-[200px] xl:min-w-[250px]">
          <DxDailyPosts
            postsList={data?.dxdaily?.scrapingData ?? []}
            dxdailyId={dxdailyId}
          />
        </div>
      </div>

      {/* End of third Party Content */}
    </Layout>
  );
};

export default ProductPage;

export const Head = ({ location, data }) => {
  const productName = data?.strapiProduct?.productName ?? "";
  return (
    <SEO
      title={`${productName} | DXies`}
      description={
        "DXiesでは、デジタルトランスフォーメーションに欠かせない世界のクラウド製品をカテゴリーごとに掲載。貴重な日本語での情報をお届けします。"
      }
      path={location.pathname}
    />
  );
};

export const query = graphql`
  query GetProductData($id: String, $dxdailyId: String) {
    strapiProduct(id: { eq: $id }) {
      id
      categories {
        name
        slug
        localizations {
          data {
            attributes {
              name
              slug
              locale
            }
          }
        }
      }
      brand {
        name
        url
      }
      slug
      productName
      overview
      logo {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: TRACED_SVG
              layout: FULL_WIDTH
            )
          }
        }
      }
      keyFeatures {
        data {
          keyFeatures
        }
      }
      introductoryCompany {
        data {
          introductoryCompany
        }
      }
      featuredImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 100

              layout: FULL_WIDTH
            )
          }
        }
      }
      url
      price
      trial
      faq
      operationGuide
      integrationGuide
      developersGuide
      resourceLibrary
      knowledgeBase
      support
      blog
      news
      pressRelease
      twitter
      facebook
      linkedIn
      youtube
      vimeo
      community
      g2Com
      agency {
        name
        url
      }
      agencyPrice {
        name
        url
      }
      agencyTrial {
        name
        url
      }
      agencyFaq {
        name
        url
      }
      guideManual {
        name
        url
      }
      book {
        name
        directUrl
        amazon
        google
        rakuten
      }
      video {
        name
        url
      }
      agencySupport {
        name
        url
      }
      agencyCommunity {
        name
        url
      }
      agencyEvent {
        name
        url
      }
      agencyBlog {
        name
        url
      }
    }
    dxdaily {
      scrapingData(
        where: { companyname: $dxdailyId }
        limit: 10
        sort: "releasedate:desc"
      ) {
        link
        newstitle
        releasedate
      }
    }
  }
`;
const getCurrentCategory = (
  categories: ICategory[],
  passedCategory: ICategory
) => {
  if (passedCategory?.name != null && passedCategory?.slug != null) {
    return passedCategory;
  }
  if (categories && categories.length > 0) {
    return categories[0];
  }
  return undefined;
};

const localizeCategories = (categories: any[], locale: string) => {
  const localizedCategories = categories.map((category) => {
    return (
      category.localizations.data?.filter(
        (locale) => locale.attributes.locale === "ja"
      )[0]?.attributes ?? { name: category.name, slug: category.slug }
    );
  });
  localizedCategories.sort((a, b) => a.name.localeCompare(b.name));
  return localizedCategories;
};
